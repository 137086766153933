<template>
  <div id="goods" @click="docClick">
    <tmpl_header />

    <div class="containter" v-if="goods">
      <div class="breadcrumb">
        <ul>
          <li
            v-for="(item, i) in breadcrumb"
            :key="item.catId"
            :class="{ r: i + 1 !== breadcrumb.length }"
          >
            <router-link :to="'/search?cid=' + item.catId">{{
              item.catName
            }}</router-link>
          </li>
        </ul>
      </div>

      <div class="details">
        <div class="pictures">
          <ul class="">
            <li
              v-for="(item, i) in pictures"
              :style="i == bigPic ? '' : 'display:none;'"
              :key="item.goodsImgId"
            >
              <img :src="item.imageBigName" />
            </li>
          </ul>
          <ol class="thumbs">
            <li
              v-for="(item, i) in pictures"
              :class="{ act: i == bigPic }"
              :key="item.goodsImgId"
              :data-i="i"
              @click="showPic"
            >
              <img :src="item.imageThumName" />
            </li>
          </ol>
          <div class="op">
            <a class="favor" :class="{ favored: follow }" @click="onFavor">{{
              follow ? "已收藏" : "收藏"
            }}</a>
          </div>
        </div>

        <h1><small class="hot">热销</small> {{ goods.goodsInfoName }}</h1>

        <div class="price">
          <div class="total">
            <label>价&emsp;格</label>
            <strong>{{ goods.goodsInfoPreferPrice }}</strong>
          </div>
          <div class="promote" v-if="promotions.length">
            <label>促&emsp;销</label>
            <ul>
              <!--li><span>满减</span>手机数码满2000赠好礼</li>
              <li><span>满减</span>手机数码满2000赠好礼</li-->
            </ul>
          </div>
        </div>

        <div class="props">
          <div class="prop">
            <label>颜&emsp;色</label>
            <ul>
              <li>暂无</li>
            </ul>
          </div>
          <div class="from">
            <label>发&emsp;货</label>
            <p>深圳仓</p>
          </div>
          <div class="to">
            <label>配送至</label>
            <div id="citySelecter" class="picker">
              <span @click="pickCity"
                >{{ province ? province.provinceName : "北京" }}
                {{ city ? city.cityName : " " }}
                {{ district ? district.districtName : " " }}</span
              >
              <div class="selecter" v-if="showCityPicker">
                <ul>
                  <li
                    v-for="item in provinces"
                    :key="item.provinceId"
                    :class="{
                      act: province && province.provinceId == item.provinceId,
                    }"
                    @click="changeCity(item, 'province')"
                  >
                    {{ item.provinceName }}
                  </li>
                </ul>
                <ul>
                  <li
                    v-for="item in citys"
                    :key="item.cityId"
                    :class="{
                      act: city && city.cityId == item.cityId,
                    }"
                    @click="changeCity(item, 'city')"
                  >
                    {{ item.cityName }}
                  </li>
                </ul>
                <ul>
                  <li
                    v-for="item in districts"
                    :key="item.districtId"
                    :class="{
                      act: district && district.districtId == item.districtId,
                    }"
                    @click="changeCity(item, 'district')"
                  >
                    {{ item.districtName }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="num">
            <label>数&emsp;量</label>
            <div class="numer">
              <a
                href="javascript:;"
                :class="{ disable: num <= 1 }"
                class="reduce"
                @click="numReduce"
              ></a>
              <input v-model="num" @blur="checkNum" />
              <a
                href="javascript:;"
                :class="{ disable: num >= max }"
                class="increase"
                @click="numIncrease"
              ></a>
            </div>
          </div>
        </div>

        <div class="btns" v-if="max > 0">
          <a class="buy" @click="toBuy">立即购买</a>
          <a class="cart" @click="toCart">加入购物车</a>
        </div>

        <div class="btns" v-if="max <= 0">
          <a class="buy dis" href="javascript:;">已售罄</a>
        </div>
      </div>
    </div>

    <div v-if="goods" class="containter clear">
      <div class="recommends">
        <h3>热销排行</h3>
        <div class="tabs">
          <a
            :class="{ act: hotTab === 'hotSaleGoodsPrices' }"
            href="javascript:;"
            data-tab="hotSaleGoodsPrices"
            @click="switchHotTab"
            >同价位</a
          >
          <a
            :class="{ act: hotTab === 'hotSaleBrands' }"
            href="javascript:;"
            data-tab="hotSaleBrands"
            @click="switchHotTab"
            >同品牌</a
          >
          <a
            :class="{ act: hotTab === 'hotSaleCats' }"
            href="javascript:;"
            data-tab="hotSaleCats"
            @click="switchHotTab"
            >同类别</a
          >
        </div>
        <ul>
          <li v-for="(pro, i) in hotSale[hotTab]" :key="pro.goods_id">
            <router-link class="pro" :to="`/goods/view/${pro.goods_id}`">
              <i class="top" :class="`top${i + 1}`" v-if="i < 3"
                ><small>{{ i + 1 }}</small></i
              >
              <img :src="pro.goods_info_img_id" />
              <strong>{{ pro.goods_info_name }}</strong>
              <p>{{ pro.goods_info_prefer_price }}</p>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="intros">
        <div class="tabs">
          <a
            :class="{ act: goodsTab === 'description' }"
            @click="switchTab('description')"
            >商品详情</a
          >
          <a
            :class="{ act: goodsTab === 'aftersale' }"
            @click="switchTab('aftersale')"
            >售后保障</a
          >
          <a
            v-if="reviews.allComments"
            :class="{ act: goodsTab === 'reviews' }"
            @click="switchTab('reviews')"
            >商品评价<i>{{ reviews.allComments.length }}</i></a
          >
        </div>

        <section v-if="goodsTab === 'description'" class="description">
          <div class="attrs">
            <ul>
              <li
                v-for="(item, i) in params"
                :style="i <= 5 || moreProm ? '' : 'display:none;'"
                :key="item.param_name"
              >
                <label>{{ item.param_name }} ：</label>
                <p>{{ item.param_value }}</p>
              </li>
            </ul>
            <a class="more" @click="moreProm = !moreProm"
              >{{ moreProm ? "收起" : "更多" }} »</a
            >
          </div>
          <div class="introdetails" v-html="goods.goodsDetailDesc"></div>
        </section>

        <section class="aftersale" v-if="goodsTab === 'aftersale'"></section>

        <section
          class="reviews"
          v-if="reviews.allComments && goodsTab === 'reviews'"
        >
          <div class="total">
            <strong>{{ goods.scopeFlag }}%</strong>
            <a
              :class="{ act: reviewsTab == 'all' }"
              @click="switchReviewsTab('all')"
              >全部({{ reviews.allComments.length }})</a
            >
            <a
              :class="{ act: reviewsTab == 'good' }"
              @click="switchReviewsTab('good')"
              >好评({{ reviews.goodComments.length }})</a
            >
            <a
              :class="{ act: reviewsTab == 'middle' }"
              @click="switchReviewsTab('middle')"
              >中评({{ reviews.middleComments.length }})</a
            >
            <a
              :class="{ act: reviewsTab == 'bad' }"
              @click="switchReviewsTab('bad')"
              >差评({{ reviews.badComments.length }})</a
            >
          </div>
          <ul>
            <li
              v-for="item in reviews[`${reviewsTab}Comments`]"
              :key="item.commentId"
            >
              <p class="user">
                <img src="../../assets/demo/user.png" />
                <b>{{ item.customerNickname }}</b>
              </p>
              <p class="score">
                <i :class="'star' + Math.round(item.commentScore / 2)"></i>
              </p>
              <p class="review">
                {{ item.commentContent }}
              </p>
            </li>
          </ul>
        </section>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      productId: 0,

      breadcrumb: [],
      goods: null,
      follow: false,
      pictures: [],
      params: [],
      hotSale: {},
      reviews: [],
      promotions: [],

      province: null,
      city: null,
      district: null,
      provinces: [],
      citys: [],
      districts: [],
      showCityPicker: false,

      bigPic: 0,
      num: 1,
      max: 1,
      moreProm: false,
      hotTab: "hotSaleGoodsPrices",
      goodsTab: "description",
      reviewsTab: "all",
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    this.productId = this.$route.params.id;
    // console.log(this.$route);

    api
      .all([this.loadDetail(), this.loadBreadcrumb(), this.loadProvince()])
      .then(
        api.spread(() => {
          this.loading = false;
        })
      )
      .catch((err) => {
        console.log(err);
        // store.dispatch("alert", err);
      });
  },

  methods: {
    loadBreadcrumb: function () {
      return api
        .post(apis.goods.breadcrumb, {
          productId: this.productId,
        })
        .then((res) => {
          this.breadcrumb = res;
        });
    },

    loadDetail: function () {
      return api
        .post(apis.goods.detail, {
          productId: this.productId,
        })
        .then((res) => {
          this.follow = res.isFollow;
          this.goods = res.productDetail;
          this.pictures = res.imageList;
          this.params = res.goodsParam;
          this.hotSale = res.hotSaleMap;
          this.reviews = res.commentMap;
          this.promotions = res.promotionMap;
          this.max = parseInt(res.stock, 10);
        });
    },

    loadProvince: function () {
      return api.post(apis.common.province, {}).then((res) => {
        this.provinces = res;
        const pos = sessionStorage.getItem("topnav_pos");
        if (pos) {
          res.map((item) => {
            if (-1 !== pos.indexOf(item.provinceName)) {
              this.changeCity(item, "province").then(() => {
                this.citys.map((item) => {
                  if (-1 !== pos.indexOf(item.cityName)) {
                    this.city = item;
                    this.changeCity(item, "city");
                  }
                });
              });
            }
          });
        }
      });
    },

    changeCity: function (sel, type) {
      if (type == "province") {
        this.province = sel;
        this.city = null;
        this.district = null;
        return api
          .post(apis.common.city, { provinceId: this.province.provinceId })
          .then((res) => {
            this.citys = res;
            this.districts = [];
          });
      } else if (type == "city") {
        this.city = sel;
        this.district = null;
        return api
          .post(apis.common.district, { cityId: this.city.cityId })
          .then((res) => {
            this.districts = res;
          });
      } else {
        this.district = sel;
        this.showCityPicker = false;
      }
    },

    pickCity() {
      this.showCityPicker = !this.showCityPicker;
    },

    docClick(e) {
      var target = e.target,
        citySel = document.getElementById("citySelecter");

      if (!citySel.contains(target)) {
        this.showCityPicker = false;
      }
    },

    switchTab(tab) {
      this.goodsTab = tab;
    },

    switchHotTab(e) {
      var tab =
        e.currentTarget && e.currentTarget.dataset
          ? e.currentTarget.dataset.tab
          : "hotSaleGoodsPrices";
      this.hotTab = tab;
    },

    switchReviewsTab(tab) {
      this.reviewsTab = tab;
    },

    showPic(e) {
      var i =
        e.currentTarget && e.currentTarget.dataset
          ? e.currentTarget.dataset.i
          : 0;
      this.bigPic = i;
    },

    checkNum() {
      if (!this.num || this.num <= 0) {
        this.num = 1;
      } else {
        this.num = parseInt(this.num, 10);
        if (isNaN(this.num)) this.num = 1;

        if (this.num >= this.max) this.num = this.max;
      }
    },

    numIncrease() {
      if (this.num < this.max) {
        this.num += 1;
      }
    },

    numReduce() {
      if (this.num > 1) {
        this.num -= 1;
      }
    },

    onFavor() {
      if (!this.username) {
        return store.dispatch("alert", {
          icon: "warning",
          msg: "您还没有登录账号",
        });
      }

      const params = {
        follows: [
          {
            goodsId: this.productId,
            followPrice: this.goods.goodsInfoPreferPrice,
          },
        ],
      };
      api.post(apis.center.addFavor, params).then((res) => {
        console.log(res);
        store.dispatch("toast", "已加入收藏");
      });
    },

    toBuy() {
      if (!this.username) {
        return store.dispatch("alert", {
          icon: "warning",
          msg: "您还没有登录账号",
        });
      }

      this.$router.push({
        path: "/cart/submit",
        query: { productId: this.productId, num: this.num },
      });
    },

    toCart() {
      if (!this.username) {
        return store.dispatch("alert", {
          icon: "warning",
          msg: "您还没有登录账号",
        });
      }

      const params = {
        goodsNum: this.num,
        productId: this.productId,
        distinctId: 1, // 这个有啥用
      };
      api.post(apis.cart.add, params).then((res) => {
        console.log(res);
        store.dispatch("needUpdateCart");
        store.dispatch("toast", "已加入购物车");
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./view.less");
</style>
